const getRoles = user => {
    if (user && user.profile && user.profile.role) {
        const roles = user.profile.role
        if (Array.isArray(roles)) {
            return roles.join(',')
        } else {
            return roles
        }
    } else {
        return ''
    }
}

const checkRole = (role, roles) => {
    if (roles && role) {
        return roles.includes(role)
    } else {
        return false
    }
}

const hasAccessToAdmin = roles => checkRole('Administrator', roles)

export {
    getRoles,
    checkRole,
    hasAccessToAdmin
}
