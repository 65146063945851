const routePaths = {
    landing: 'features/landing/LandingPage',
    login: 'features/landing/Login',
    facility: 'features/facility/Facility',
    facilities: 'features/facilities/Facilities',
    bidPackage: 'features/bid-package/BidPackage',
    bidPackages: 'features/bid-packages/BidPackages',
    bidResponse: 'features/bid-response/BidResponse',
    bidResponses: 'features/bid-responses/BidResponses',
    invoice: 'features/invoice/Invoice',
    invoices: 'features/invoices/Invoices',
    callback: 'features/user/CallbackComponent'
}

const getRoutePathByPageName = (name) => {
    if (typeof routePaths[name] === 'undefined') {
        throw new Error(`The route path ${name} is not present in routePaths.js`)
    }
    return routePaths[name]
}

export { getRoutePathByPageName }
export default routePaths