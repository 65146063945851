import RestrictedAccess from './RestrictedAccess'

const EditorAccess = ({ children }) => {
    return (
        <RestrictedAccess
            allowedRoles={['Administrator', 'Editor',]}
        >
            {children}
        </RestrictedAccess>
    )
}

export default EditorAccess