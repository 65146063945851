import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import withConfig from '../wrappers/withConfig'

const Header = ({ config }) => {
    const { APP_TITLE } = config
    return (
        <HelmetProvider>
            <Helmet>
                <title>{APP_TITLE}</title>
            </Helmet>
        </HelmetProvider>
    )
}

export default withConfig(Header)
