const urls = {
    home: '/',
    login: '/login',
    userCallback: '/user/callback',
    facility: '/facility',
    facilities: '/facilities',
    bidPackage: '/bidPackage',
    bidPackages: '/bidPackages',
    bidResponse: '/bidResponse',
    bidResponses: '/bidResponses',
    invoice: '/invoice',
    invoices: '/invoices',
    projects: '/projects',
    project: '/project',
    features: [
        {
            name: 'Facilities',
            url: '/facilities'
        },
        {
            name: 'Bid Packages',
            url: '/bidPackages'
        }
        ,
        {
            name: 'Bid Responses',
            url: '/bidResponses'
        }
        ,
        {
            name: 'Invoices',
            url: '/invoices'
        },
    ]
}

const apiUrls = {
    facility: 'facility',
    facilityGroup: 'facilityGroup',
    bidPackage: 'bidPackage',
    bidResponse: 'bidResponse',
    invoice: 'invoice',
    schema: 'schema',
    lookup: 'lookup',
    autoCalc: 'autoCalculate',
    upload: 'upload'
}

const getCreateLink = (url) => {
    return `${url}/new`
}

export { apiUrls, getCreateLink }
export default urls
